import React, { useState } from "react";
import InquiryModal from "../InquiryModal";
import benifits from "../../assets/benifits.jpg";

const MachineParts = () => {
  const [isOpenInquiryModal, setOpenInquiryModal] = useState(false);
  const handleInquiryClick = () => {
    setOpenInquiryModal(true);
  };
  return (
    <div>
      {isOpenInquiryModal && (
        <InquiryModal setOpenInquiryModal={setOpenInquiryModal} />
      )}
      <div className="lg:flex bg-[#f6f6f6] py-6 md:py-8  lg:py-12 ">
        <div
          className="left flex-1 flex justify-center items-center mt-5 md:mt-3 lg:mt-0"
          data-aos="fade-left"
        >
          <img
            src={benifits}
            alt="machine-girl"
            className="w-full md:w-4/5 xl:w-2/3 h-[30vh]  md:h-[60vh] "
          />
        </div>
        <div
          className="right flex-1 flex flex-col justify-center mt-6 md:mt-6 lg:mt-0 px-5 md:px-16  "
          data-aos="fade-right"
        >
          <h2 className="text-green font-bold text-xl md:text-2xl">
            Benefits of Jigrra's Sugarcane Juice Machine
          </h2>
          {/* <p className=" text-lg md:text-lg font-bold mt-4 md:mt-6  lg:mt-6 lg:pr-2">
            73% Juice Extraction By Weight of Sugarcane by Single Crushing
          </p> */}
          <p className="text-black text-lg md:text-xl mt-4 md:mt-6  lg:mt-6 lg:pr-2">
            JigrraCrush is compatct yet efficient and cost effective machine. It
            reduce the time and men power because of it's easy control panel.
            JigrraCrush is crafted from stainless steel SS304 - food grill. It
            is rust-free and durable, built to last long and save your money on
            servicing. Without compromising on hygiene standards this machine
            operates smoothly with minimal human assistance.
          </p>
          <p className="text-lg md:text-xl mt-4 text-green">
            Top Benefits of JigrraCrush
          </p>
          <ul className="mt-4 ">
            <li className="text-lg">
              &#8226; Compact size | Fits in Small Place
            </li>
            <li className="text-lg">&#8226; 100% hygienic</li>
            <li className="text-lg">
              &#8226; 74% of Weight Extraction Capacity
            </li>
            <li className="text-lg">&#8226; Low maintenance & Durable</li>
            <li className="text-lg">&#8226; Low Power Consumption</li>
          </ul>

          <div className="flex justify-center md:justify-start">
            <button
              onClick={handleInquiryClick}
              className="w-fit bg-green mt-6 md:mt-7 hover:bg-white font-semibold  text-white hover:text-green px-5 border-2 border-[#47b200] py-2 rounded-2xl"
            >
              Enquiry Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MachineParts;
