import React from 'react'

const ContactUs = () => {
  return (
    <div>
      Contact Us
    </div>
  )
}

export default ContactUs
