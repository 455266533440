import JigraaSugarCrush from "../assets/JigrraFront.jpg";
export const productData = [
  {
    id: 1,
    productPath: "jigrracrush",
    productTitle: "JigrraCrush - Sugarcane Juice Machine",
    detail:
      "We are the trusted sugarcane juice machine manufacturer in India making machines that are hyenic, easy to use and made for diverse industries.",
    image: JigraaSugarCrush,
    price: 47200,
    productSubtitle: "Instant Sugarcane Juice within a minute",
    productName: "JigrraCrush",
    keyFeatures: [
      "Compact design suitable for placement in front of customers.",
      "Consistently delivers fresh, pure, and hygienic juice.",
      "Rollers made of food-grade stainless steel for durability and hygiene, unlike plastic alternatives.",
      "Rust-free and sturdy construction requiring minimal support or upkeep.",
      "Saves on overhead costs by minimizing maintenance expenses.",
      "Doesn't require periodic lubrication, unlike other machines on the market.",
      "Very economical, consuming as low as 1/2 HP (400 Watts) resulting in lower electricity bills.",
      "Consideration of this point is crucial before purchasing, as it signifies earning more rather than spending more.",
    ],
  },
];