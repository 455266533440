import React from "react";
import whatsupImg from "../../assets/whatsapp-icon.svg";

function FloatBtn() {
  return (
    <div className="fixed z-20 rounded-md transform cursor-pointer lg:right-7 md:right-7 right-3.5 bottom-20 md:bottom-10">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://wa.me/919773479180?text=Hello%20%2C%20I%20have%20a%20questions%20about%20your%20services.%20Can%20you%20please%20help%20me%3F"
        >
          <div className="h-14 w-14 rounded-full bg-transparent flex justify-center items-center">
            <img
              src={whatsupImg}
              alt="Whatsapp"
              className=""
              height="112"
              width="112"
            />
          </div>
        </a>
      </div>
  );
}

export default FloatBtn;
