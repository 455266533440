import React, { useState, useRef, lazy, Suspense } from "react";
import Header from "../Header";
import Footer from "../Footer";
import FloatBtn from "../FloatBtn";
const StickyShareButtons = lazy(() => 
  import("sharethis-reactjs").then(module => ({ default: module.StickyShareButtons }))
);
function Layout({ children }) {
  const footerRef = useRef(null);
  const [scrollToBottom, setScrollToBottom] = useState(false);

  return (
    <div className="overflow-x-hidden">
      <Suspense fallback={<div>Loading share buttons...</div>}>
        <StickyShareButtons
          config={{
            alignment: "left", // alignment of buttons (left, center, right)
            color: "social", // set the color of buttons (social, white)
            enabled: true, // show/hide buttons (true, false)
            font_size: 16, // font size for the buttons
            labels: "null", // button labels (cta, counts, null)
            language: "en", // which language to use (see LANGUAGES)
            networks: [
              // which networks to include (see SHARING NETWORKS)

              "facebook",
              "twitter",
              "pinterest",
              // Added Instagram
            ],
            container: "x",
            id: "blog",
            padding: 12, // padding within buttons (INTEGER)
            radius: 4, // the corner radius on each button (INTEGER)
            show_total: false, // show/hide the total share count (true, false)
            show_mobile: true, // show/hide the buttons on mobile (true, false)
            show_toggle: true, // show/hide the toggle buttons (true, false)
            size: 48, // the size of each button (INTEGER)
            top: 250, // offset in pixels from the top of the page
            show_mobile_buttons: true,
            // OPTIONAL PARAMETERS

            username: "sugarcane", // (only for twitter sharing)
          }}
        />
      </Suspense>
      <Header setScrollToBottom={setScrollToBottom} />
      <main>{children}</main>
      <div id="footer-id1">
        <Footer footerRef={footerRef} scrollToBottom={scrollToBottom} />
      </div>

      {/* <FooterFinal/> */}
      <FloatBtn />
    </div>
  );
}

export default Layout;
