import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa6";
import { GrFacebookOption, GrInstagram } from "react-icons/gr";
import { BiLogoYoutube } from "react-icons/bi";
import { MdOutlineEmail } from "react-icons/md";
import { IoCallSharp, IoLocationSharp } from "react-icons/io5";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/logo.svg";
import { RiSecurePaymentLine } from "react-icons/ri";
import { PiMedalLight } from "react-icons/pi";
import { IoHelpBuoyOutline } from "react-icons/io5";
import { LiaShippingFastSolid } from "react-icons/lia";

const Footer = ({footerRef,scrollToBottom}) => {


  const email = "jigrrasugarcrush@gmail.com";
  const telephoneNumber1 = 919773479180;
  const telephoneNumber2 = 918238470062;

  return (
    <footer ref={footerRef}>
      <ToastContainer />
      <div className="bg-neutral-700" id="footer-id">
        <div className="ml-4 mr-4 lg:mx-8 md:mx-6 mx-auto py-12 sm:px-0 lg:pt-16 lg:pb-8 lg:px-0">
          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mx-auto max-w-6xl gap-4 lg:gap-8 pb-6">
            <div className="capitalize md:col-span-1">
              <img src={logo} className="w-1/3  md:w-2/4   " />
            </div>
            <div className="md:col-span-1">
              <div className="capitalize text-xl font-semibold text-green">
                Contact us
              </div>
              <div>
                <ul className="mt-4">
                  <li className="mt-4" key={Math.random()}>
                    <a
                      href={`mailto:${email}`}
                      className="text-sm leading-3 text-white flex items-center gap-3 pb-3"
                    >
                      <div className="py-2 flex items-center justify-center">
                        <MdOutlineEmail className="text-xl text-green" />
                      </div>
                      <div>{email}</div>
                    </a>
                    <div className="text-sm leading-3 text-white flex items-center gap-3 pb-3">
                      <div className="py-2 flex items-center justify-center">
                        <IoCallSharp className="text-xl text-green" />
                      </div>
                      <div className="flex flex-col gap-2">
                        <Link
                          to={`tel:${telephoneNumber1}`}
                        >{`+${telephoneNumber1}`}</Link>
                        <Link
                          to={`tel:${telephoneNumber2}`}
                        >{`+${telephoneNumber2}`}</Link>
                      </div>
                    </div>
                    <Link
                      to={"/contact"}
                      className="text-sm leading-3 text-white flex items-center gap-3 pb-3"
                    >
                      <div className="py-2 flex items-center justify-center">
                        <IoLocationSharp className="text-xl text-green" />
                      </div>
                      <div className="mt-3">
                        <div className="font-bold">M.K. TECH ENGINEERING</div>
                        <p className="mt-3 font-semibold">JIGRRA Sugar Crush</p>
                        <br />
                        <p className="leading-5 pe-6">
                          Plot No. 14, Swapnil Industries Park, Near Vaikunth
                          Hotel, Indian Oil Petrol Pump, Indore Highway, Kuha,
                          Ahmedabad - 382433, Gujarat, India
                        </p>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:col-span-1">
              <div>
                <div className="capitalize text-xl font-semibold text-green">
                  Quick Links
                </div>
                <div>
                  <ul className="mt-4">
                    <li className="mt-4" key={Math.random()}>
                      <Link
                        to={"/"}
                        className="text-sm leading-3 text-white flex items-center gap-3 pb-3"
                      >
                        <div className="py-2 flex items-center justify-center">
                          <FaAngleRight className="text-green" />
                        </div>
                        <span>Home</span>
                      </Link>
                      <Link
                        to={"/about"}
                        className="text-sm leading-3 text-white flex items-center gap-3 pb-3"
                      >
                        <div className="py-2 flex items-center justify-center">
                          <FaAngleRight className="text-green" />
                        </div>
                        <span>About</span>
                      </Link>
                      <Link
                        to={"/contact"}
                        className="text-sm leading-3 text-white flex items-center gap-3 pb-3"
                      >
                        <div className="py-2 flex items-center justify-center">
                          <FaAngleRight className="text-green" />
                        </div>
                        <span>Contact</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="md:col-span-1">
              <div>
                <div className="capitalize text-xl font-semibold text-green">
                  Follow us
                </div>
                <div>
                  <ul className="mt-4 ">
                    <li className="mt-4" key={Math.random()}>
                      <Link
                        to={"/#"}
                        className="text-sm leading-3 text-white flex items-center gap-3 pb-3"
                      >
                        <div className="p-2 rounded-full bg-neutral-600 flex items-center justify-center">
                          <GrFacebookOption className="text-green" />
                        </div>
                        <span>Facebook</span>
                      </Link>
                      <Link
                        to={"/#"}
                        className="text-sm leading-3 text-white flex items-center gap-3 pb-3"
                      >
                        <div className="p-2 rounded-full bg-neutral-600 flex items-center justify-center">
                          <GrInstagram className="text-green" />
                        </div>
                        <span>Instagram</span>
                      </Link>
                      <Link
                        to={"/#"}
                        className="text-sm leading-3 text-white flex items-center gap-3 pb-3"
                      >
                        <div className="p-2 rounded-full bg-neutral-600 flex items-center justify-center">
                          <BiLogoYoutube className="text-green" />
                        </div>
                        <span>Youtube</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="grid lg:grid-cols-3 md:grid-cols-3 gap-10 items-start  pt-10 mb-10 border-t border-neutral-400">
            <div className="flex flex-col justify-center items-center px-10">
              <div className="w-24 h-24 bg-green  border border-solid border-neutral-500 rounded-full relative">
                <RiSecurePaymentLine className="absolute text-white inset-0 m-auto text-6xl" />
              </div>
              <div className="text-xl font-semibold text-white  uppercase text-center py-2">
                24/7 SUPPORT
              </div>
            </div>

            <div className="flex flex-col justify-center items-center px-10">
              <div className="w-24 h-24  bg-green border border-solid border-neutral-500 rounded-full relative">
                <IoHelpBuoyOutline className="absolute text-white inset-0 m-auto text-6xl" />
              </div>
              <div className="text-xl font-semibold text-white text-center py-2">
                HELP CENTER
              </div>
            </div>
            <div className="flex flex-col justify-center items-center px-10">
              <div className="w-24 h-24 bg-green border border-solid border-neutral-500 rounded-full relative">
                <LiaShippingFastSolid className="absolute text-white inset-0 m-auto text-6xl" />
              </div>
              <div className="text-xl font-semibold text-white uppercase text-center py-2">
                EXPRESS SHIPPING
              </div>
            </div>
          </div>
          <div className="border-t border-neutral-400  text-green pt-6">
            <p className="text-base leading-6  text-white text-center">
              &copy; {new Date().getFullYear()} Jigrra
              <br className="md:hidden" /> All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
