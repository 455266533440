import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { productData } from "../../helpers/constants";
import { TbPhoneCall } from "react-icons/tb";
import { RiSecurePaymentLine } from "react-icons/ri";
import { CiDeliveryTruck } from "react-icons/ci";
import { PiMedal } from "react-icons/pi";
import { Helmet } from "react-helmet";

const ProductDetail = () => {
  const location = useLocation();
  const [productDetailData, setProductDetailData] = useState([]);
  useEffect(() => {
    const path = location?.pathname?.split("/")[2];
    if (path) {
      const productDetailData = productData.find(
        (data) => data?.productPath == path
      );
      setProductDetailData(productDetailData);
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>  
         <meta property="og:type" type="website" />
         <meta property="og:title" content="JigrraCrush - Sugarcane Juice Machine" />
         <meta
            property="og:description"
            content="We are the trusted sugarcane juice machine manufacturer in India making machines that are hygiene, easy to use and made for diverse industries."
          />
          <meta
            property="og:image:alt"
            content="We are the trusted sugarcane juice machine manufacturer in India making machines that are hygiene, easy to use and made for diverse industries."
          />
      </Helmet>
      <div className="mt-12 lg:mt-16">
        <div className="grid lg:grid-cols-2 py-10 md:py-14 lg:py-16 px-5 md:px-14 lg:px-24 gap-6 lg:gap-10">
          <div className="hidden md:block">
            <img src={productDetailData?.image} alt="image" />
          </div>
          <div>
            <h1 className="text-4xl font-semibold pb-3" data-aos="fade-down">
              {productDetailData?.productTitle}
            </h1>
            <div className="block mt-3 mb-5 md:hidden">
            <img src={productDetailData?.image} alt="image" />
          </div>
            <div
              data-aos="zoom-in-left"
              className="text-2xl text-green font-semibold pb-1"
            >
              {productDetailData?.productSubtitle}
            </div>

            <div data-aos="zoom-in-left" className="text-xl font-semibold pb-2">
              {productDetailData?.productFeatures}
            </div>
            <div className="text-2xl font-bold pb-4" data-aos="fade-left">
              {productDetailData?.price?.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
              })}
            </div>

            <div className="">
              <ul data-aos="zoom-in" className="text-lg list-disc pl-5">
                {productDetailData?.keyFeatures?.map((feature) => (
                  <li className="mb-2">{feature}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="mb-20">
          <div
            data-aos="fade-up"
            className="flex flex-col place-items-center items-center text-center pb-10 m-4 aos-init aos-animate"
          >
            <div className="md:text-4xl text-3xl font-bold text-center">
              Technical Description
            </div>
            <div>
              <hr className="h-1 w-40 mt-5 border-0 bg-green"></hr>
            </div>
          </div>
          <div class="lg:max-w-7xl mx-auto px-5 overflow-x-auto hidden sm:block">
            <table className="divide-y w-full text-lg divide-gray-200 border-collapse border border-slate-400  overflow-auto">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    colspan="4"
                    class="px-6 py-3 text-center bg-white font-bold border border-slate-400 "
                  >
                    {productDetailData?.productName}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="px-4 py-2 text-center font-bold border border-slate-400 bg-gray-100">
                    Motor Power
                  </td>
                  <td class="px-4 py-2  text-center border border-slate-400 bg-gray-100">
                    0.5 HP
                  </td>
                  <td class="px-4 py-2 font-bold text-center border border-slate-400 bg-gray-100">
                    Capacity(glass/hr)
                  </td>
                  <td class="px-4 py-2 lg text-center border border-slate-400 bg-gray-100">
                    400 glass/hr
                  </td>
                </tr>
                <tr>
                  <td class="px-4 py-2 text-center font-bold border border-slate-400 ">
                    Automation Grade
                  </td>
                  <td class="px-4 py-2 text-center border border-slate-400 ">
                    Automatic
                  </td>
                  <td class="px-4 py-2  font-bold text-center border border-slate-400 ">
                    Yield
                  </td>
                  <td class="px-4 py-2  text-center border border-slate-400 ">
                    300 ml/kg
                  </td>
                </tr>
                <tr>
                  <td class="px-4 py-2 text-center font-bold border border-slate-400 bg-gray-100">
                    Warranty
                  </td>
                  <td class="px-4 py-2  text-center border border-slate-400 bg-gray-100">
                    2 Year
                  </td>
                  <td class="px-4 py-2 font-bold text-center border border-slate-400 bg-gray-100">
                    Material
                  </td>
                  <td class="px-4 py-2 text-center border border-slate-400 bg-gray-100">
                    SS
                  </td>
                </tr>
                <tr>
                  <td class="px-4 py-2 text-center font-bold border border-slate-400">
                    Usage/Application
                  </td>
                  <td class="px-4 py-2 text-center border border-slate-400">
                    Commercial
                  </td>
                  <td class="px-4 py-2 text-center font-bold border border-slate-400">
                    Phase
                  </td>
                  <td class="px-4 py-2 text-center border border-slate-400">
                    Single Phase
                  </td>
                </tr>
                <tr>
                  <td class="px-4 py-2 text-center font-bold border border-slate-400 bg-gray-100">
                    Voltage
                  </td>
                  <td class="px-4 py-2 text-center border border-slate-400 bg-gray-100">
                    240V
                  </td>
                  <td class="px-4 py-2 text-center font-bold border border-slate-400 bg-gray-100">
                    Brand
                  </td>
                  <td class="px-4 py-2 text-center border border-slate-400 bg-gray-100">
                    Jigrra
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="lg:max-w-7xl mx-auto px-5 overflow-x-auto block sm:hidden">
            <table className="divide-y text-lg w-full divide-gray-200 border-collapse border border-slate-400  overflow-auto">
              <tbody>
                <tr>
                  <td class="px-4 py-2 text-center font-bold border border-slate-400 bg-gray-100">
                    Motor Power
                  </td>
                  <td class="px-4 py-2  text-center border border-slate-400 bg-gray-100">
                    0.5 HP
                  </td>
                </tr>
                <tr>
                  <td class="px-4 py-2 font-bold text-center border border-slate-400 ">
                    Capacity(glass/hr)
                  </td>
                  <td class="px-4 py-2 lg text-center border border-slate-400">
                    400 glass/hr
                  </td>
                </tr>
                <tr>
                  <td class="px-4 py-2 text-center font-bold border border-slate-400 bg-gray-100">
                    Automation Grade
                  </td>
                  <td class="px-4 py-2 text-center border border-slate-400 bg-gray-100">
                    Automatic
                  </td>
                </tr>
                <tr>
                  <td class="px-4 py-2  font-bold text-center border border-slate-400 ">
                    Yield
                  </td>
                  <td class="px-4 py-2  text-center border border-slate-400 ">
                    300 ml/kg
                  </td>
                </tr>
                <tr>
                  <td class="px-4 py-2 text-center font-bold border border-slate-400 bg-gray-100">
                    Warranty
                  </td>
                  <td class="px-4 py-2  text-center border border-slate-400 bg-gray-100">
                    2 Year
                  </td>
                </tr>
                <tr>
                  <td class="px-4 py-2 font-bold text-center border border-slate-400 ">
                    Material
                  </td>
                  <td class="px-4 py-2 text-center border border-slate-400 ">
                    SS
                  </td>
                </tr>
                <tr>
                  <td class="px-4 py-2 text-center font-bold border border-slate-400 bg-gray-100">
                    Usage/Application
                  </td>
                  <td class="px-4 py-2 text-center border border-slate-400 bg-gray-100">
                    Commercial
                  </td>
                </tr>
                <tr>
                  <td class="px-4 py-2 font-bold text-center border border-slate-400 ">
                    Voltage
                  </td>
                  <td class="px-4 py-2 text-center border border-slate-400 ">
                    240V
                  </td>
                </tr>
                <tr>
                  <td class="px-4 py-2 font-bold text-center border border-slate-400 bg-gray-100">
                    Phase
                  </td>
                  <td class="px-4 py-2 text-center border border-slate-400 bg-gray-100">
                    Single Phase
                  </td>
                </tr>
                <tr>
                  <td class="px-4 py-2 font-bold text-center border border-slate-400 ">
                    Brand
                  </td>
                  <td class="px-4 py-2 text-center border border-slate-400 ">
                    Jigrra
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="mb-20 px-5">
          <div
            data-aos="fade-up"
            className="flex flex-col place-items-center items-center text-center pb-10 m-4 aos-init aos-animate"
          >
            <div className="md:text-4xl text-3xl font-bold text-center">
              Features
            </div>
            <div>
              <hr className="h-1 w-40 mt-5 border-0 bg-green"></hr>
            </div>
          </div>
          <div className="grid md:grid-cols-3 items-start p-2 md:p-6 lg:px-12 lg:py-8 bg-darkGreen max-w-7xl mx-auto rounded-2xl">
            <div className="flex flex-col items-center justify-center md:border-r-2 md:border-white gap-4 p-8 md:p-2 ">
              <div className="w-20 md:w-16 h-20 md:h-16 rounded-full relative bg-white">
                <PiMedal className="absolute text-green inset-0 m-auto text-4xl md:text-3xl" />
              </div>
              <div className="text-2xl md:text-xl font-bold text-white text-center">
                2 Year Replacement Warranty
              </div>
            </div>
            <div className="flex flex-col items-center justify-center gap-4 md:border-r-2 md:border-white p-8 md:p-2 mr-2">
              <div className="w-20 md:w-16 h-20 md:h-16 rounded-full relative bg-white">
                <TbPhoneCall className="absolute text-green inset-0 m-auto text-4xl md:text-3xl" />
              </div>
              <div className="text-2xl md:text-xl font-bold text-white text-center">
                24/7 Support
              </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-4 p-8 md:p-2 mr-2">
              <div className="w-20 md:w-16 h-20 md:h-16 rounded-full relative bg-white">
                <CiDeliveryTruck className="absolute text-green inset-0 m-auto text-4xl md:text-3xl" />
              </div>
              <div className="text-2xl md:text-xl font-bold text-white text-center">
                Express Delivery
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetail;
