import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Banner from "../../assets/banner.jpg";

const Hero = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <div className="w-full ">
      {/* <Slider {...settings} className="w-full">
        <div>
          <img
            src="https://rigourindia.com/wp-content/uploads/2022/12/banner-01-1536x518.jpg"
            alt="banner1"
            className="w-full h-auto"
          />
        </div>
        <div>
          <img
            src="https://rigourindia.com/wp-content/uploads/2022/12/banner-01-1536x518.jpg"
            alt="banner1"
            className="w-full h-auto"
          />
        </div>
        <div>
          <img
            src="https://rigourindia.com/wp-content/uploads/2022/12/banner-01-1536x518.jpg"
            alt="banner1"
            className="w-full h-auto"
          />
        </div>
        <div>
          <img
            src="https://rigourindia.com/wp-content/uploads/2022/12/banner-01-1536x518.jpg"
            alt="banner1"
            className="w-full h-auto"
          />
        </div>
        <div>
          <img
            src="https://rigourindia.com/wp-content/uploads/2022/12/banner-01-1536x518.jpg"
            alt="banner1"
            className="w-full h-auto"
          />
        </div>
        <div>
          <img
            src="https://rigourindia.com/wp-content/uploads/2022/12/banner-01-1536x518.jpg"
            alt="banner1"
            className="w-full h-auto"
          />
        </div>
      </Slider> */}
      <img
            src={Banner}
            alt="banner1"
            className="w-full h-[26vh] md:h-auto"
          />
    </div>
  );
};

export default Hero;
