import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import NotFound from "./pages/NotFound";
import ProductDetail from "./pages/ProductDetail";
import { useEffect } from "react";
import Layout from "./components/Layout";

function App() {
  useEffect(() => {
    const AOS = require("aos");
    AOS.init({
      once: false,
      offset: 100,
      duration: 700,
      easing: "ease-out-quad",
    });
  }, []);

  useEffect(() => {
    if (AOS) {
      setTimeout(() => {
        AOS.refresh();
      }, 500);
    }
  });
  return (
    <div>
      <Layout>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/products/:name" element={<ProductDetail />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
