import React, { useState } from "react";
import InquiryModal from "../InquiryModal";
import { Link } from "react-router-dom";

function Products({ data }) {
  const [isOpenInquiryModal, setOpenInquiryModal] = useState(false);
  const [inquiryModalData, setInquiryModalData] = useState();

  const handleInquiryClick = (e, data) => {
    e.stopPropagation();
    setInquiryModalData(data);
    setOpenInquiryModal(true);
  };
  return (
    <>
      {/* <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4"> */}
      <div className="flex justify-center item-center gap-4">
        <div className="md:w-1/3 w-full">
          {data &&
            data.map((product) => (
              <>
                <Link
                  to={{
                    pathname: `/products/${product?.productPath}`, // Assuming your detail page route is /product/:id
                    state: { productData: product }, // Pass the product data in the state object
                  }}
                >
                  <div className="shadow-lg transition duration-300 ease-in-out hover:shadow-xl flex-1">
                    <img src={product?.image} alt="img" />
                    <div className="bg-white pb-3">
                      <div className=" text-center text-2xl text-green pt-2 font-bold">
                        {product?.productName}
                      </div>
                      <div className="text-center text-xl font-bold mt-2">
                        {product?.price.toLocaleString("en-IN", {
                          style: "currency",
                          currency: "INR",
                        })}
                      </div>
                    </div>
                      
                  </div>
                </Link>
                <div
                  className="w-full bg-green text-white px-5 py-3 text-center cursor-pointer font-semibold text-lg"
                  onClick={(e) => handleInquiryClick(e, product)}
                >
                  Enquiry Now
                </div>
              </>
            ))}
        </div>
      </div>
      {isOpenInquiryModal && (
        <InquiryModal
          setOpenInquiryModal={setOpenInquiryModal}
          inquiryModalData={inquiryModalData}
        />
      )}
    </>
  );
}

export default Products;
