import React, { useState } from "react";
import "./machineDetail.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Banner from "../../assets/banner.jpg";
import InquiryModal from "../InquiryModal";
import image1 from "../../assets/JigrraFront.jpg";
import image2 from "../../assets/JigrraFront1.jpg";
import image3 from "../../assets/JigrraFront2.jpg";

const MachineDetail = () => {
  const [isOpenInquiryModal, setOpenInquiryModal] = useState(false);
  const settings = {
    dots: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1000,
    className: "w-full",
  };
  const handleInquiryClick = () => {
    setOpenInquiryModal(true);
  };
  return (
    <div>
      {isOpenInquiryModal && (
        <InquiryModal setOpenInquiryModal={setOpenInquiryModal} />
      )}
      <div className="lg:flex bg-white py-6 md:py-8  lg:py-16 ">
        <div
          className="right flex-1 flex flex-col justify-center mt-6 md:mt-6 lg:mt-0 px-5 md:px-16 order-1 "
          data-aos="fade-right"
        >
          <h1 className="text-green font-bold text-3xl md:text-5xl uppercase  ">
            JigrraCrush - Sugarcane Juice Machine
          </h1>
          <p className="text-lg md:text-xl mt-4 md:mt-6  lg:mt-6 ">
            Presenting JigrraCrush, India's best sugarcane juice machine. The
            machine with 100% hygiene and offers 74% of total sugarcane weight
            extraction. Process to operate the machine is effortless and
            seemless, and provides instant fresh juice. Experience the
            convenience and purity like homemade sugarcane juice thanks to
            Jigrra's innovative design and uncompromising hygiene standards.
            Reach out us today and get your ganna juice machine -JigrraCrush.
          </p>

          <div className="flex justify-center md:justify-start">
            <button
              onClick={handleInquiryClick}
              className="bg-green hover:bg-white font-semibold w-fit mt-6 md:mt-7  text-white hover:text-green px-5 border-2 border-[#47b200] py-2 rounded-2xl"
            >
              Enquiry Now
            </button>
          </div>
        </div>
        <div
          className="left  flex justify-center items-center mt-5 md:mt-0 w-full md:w-1/2  order-2"
          data-aos="fade-left"
        >
          <Slider {...settings} className="w-full md:w-4/5">
            <div>
              <img src={image1} alt="machine-girl" className="w-full  " />
            </div>
            <div>
              <img src={image2} alt="machine-girl" className="w-full " />
            </div>
            <div>
              <img src={image3} alt="machine-girl" className="w-full " />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default MachineDetail;
