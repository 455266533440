import React from "react";
import Header from "../../components/Header";
import Hero from "../../components/Hero";
import MachineDetail from "../../components/MachineDetail";
import MachineParts from "../../components/MachineParts";
import Products from "../../components/Products";
import { productData } from "../../helpers/constants";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <div>
      <Helmet>
         <meta property="og:type" type="website" />
         <meta property="og:title" content="Sugarcane Juice Machine Manufacturer - Jigrra" />
         <meta
            property="og:description"
            content="We are the trusted sugarcane juice machine manufacturer in India making machines that are hygiene, easy to use and made for diverse industries."
          />
          <meta
            property="og:image:alt"
            content="We are the trusted sugarcane juice machine manufacturer in India making machines that are hygiene, easy to use and made for diverse industries."
          />
      </Helmet>
      <Header />
      <div className="mt-12 lg:mt-16">
        <Hero />
        <MachineDetail />
        <MachineParts />
        <div className="w-full px-5   lg:max-w-7xl mx-auto ">
          <div className="flex flex-col place-items-center items-center text-center py-6 md:py-4 m-4 aos-init aos-animate">
            <div className="md:text-4xl text-3xl text-green font-bold text-center">
              Our Products
            </div>
            <div>
              <hr className="h-1 w-40 mt-5 border-0 bg-green"></hr>
            </div>
          </div>
          <div className="mb-16">
            <Products data={productData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
